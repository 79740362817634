import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import { Sections, SectionImages } from '../components/sections'
import Banners, { ABOUT_BANNERS } from '../components/banners'

export default function About({ data }) {
	
	const { sections, portraits, backgroundColor, banners } = data?.page || {}

	return (
		<Layout backgroundColor={backgroundColor}>
			<Main className="page-gutters">
				<Banners bannerType={ABOUT_BANNERS} pageId="about" />
				<SectionImages className="xl-captions" images={portraits} />
				<Sections className="mbx4" content={sections} />
			</Main>
		</Layout>
	)
}

export { AboutMeta as Head } from "../components/meta"

export const query = graphql`

  query($slug: String!) {
		page: contentfulAbout(slug: { eq: $slug }) {
			title
			backgroundColor
			description {
				text: description
			}
			portraits {
				...ContentfulMedia
			}
			sections {
				... on Node {
					...SectionsFragment
				}
			}
			banners {
				title
				startDate
				endDate
				copy {
					raw
				}
			}
		}
  }
`